import { getEnvVar } from '@utils';

export default {
  computed: {
    isAuthorized() {
      return getEnvVar('AUTHORIZED') && !this.isPreviewMode;
    },

    isPreviewMode() {
      return this.$store.state.user.current.previewProfile || this.$store.getters.isPreviewMode;
    },
  },

  methods: {
    openAuthModal(action) {
      if (this.isPreviewMode && this.isAuthorized) {
        return;
      }

      if (action === 'tour') {
        $('#registrationModal .title span').html('to book a tour');
      } else if (action === 'waitlist') {
        $('#registrationModal .title span').html('to join a wailist');
      } else if (action === 'contact') {
        $('#registrationModal .title span').html('to contact provider');
      } else {
        $('#registrationModal .title span').html('');
      }

      $('#registrationModal').modal('show');
      $('.login-signup-modal').attr('data-action-after-login', action);
    },

    switchToLogin() {
      $('#registrationModal').modal('hide');
      $('#loginModal').modal('show');
    },

    switchToRegister() {
      $('#loginModal').modal('hide');
      $('#registrationModal').modal('show');
    },
  },
};
