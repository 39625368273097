const misc = {
  quote: 'https://res.cloudinary.com/kinside/image/upload/v1627996630/app_assets/quote.svg',
  testimonial: 'https://res.cloudinary.com/kinside/image/upload/v1627996571/app_assets/kinside_app/testimonial-photo.png',
  avatar: 'https://res.cloudinary.com/kinside/image/upload/v1627996604/app_assets/kinside_app/placeholder-avatar_2x.png',
  naviaLogo: 'https://res.cloudinary.com/kinside/image/upload/v1628157611/app_assets/navia-logo.png',
  conciergeAssistance: 'https://res.cloudinary.com/kinside/image/upload/v1628159735/app_assets/concierge-phone-circle-mask.png',
  wave: 'https://res.cloudinary.com/kinside/image/upload/v1642542808/app_assets/kinside_app/mask-group-wave.png',
};

const kinsideLogo = {
  light: 'https://res.cloudinary.com/kinside/image/upload/v1628157536/app_assets/kinside_logo/light.svg',
  dark: 'https://res.cloudinary.com/kinside/image/upload/v1628157536/app_assets/kinside_logo/dark.svg',
  wordLight: 'https://res.cloudinary.com/kinside/image/upload/v1643079260/app_assets/kinside_logo/kinside-word-light.svg',
  wordDark: 'https://res.cloudinary.com/kinside/image/upload/v1643079260/app_assets/kinside_logo/kinside-word-dark.svg',
};

const legupByKinsideLogo = {
  white: 'https://res.cloudinary.com/kinside/image/upload/v1665539016/app_assets/legup_logo/legup_powered_by_kinside_white.svg',
  fullColorWhite: 'https://res.cloudinary.com/kinside/image/upload/v1665085974/app_assets/legup_logo/legup_powered_by_kinside_full_color_light.svg',
  simpleColor: 'https://res.cloudinary.com/kinside/image/upload/v1661877280/app_assets/legup_logo/legup_simple_color.svg',
  smallAllColor: 'https://res.cloudinary.com/kinside/image/upload/v1661783324/app_assets/legup_logo/legup_powered_by_kinside_small_color.svg',
  fullColor: 'https://res.cloudinary.com/kinside/image/upload/v1661783273/app_assets/legup_logo/legup_powered_by_kinside_full_color.svg',
};

const illustrations = {
  block: 'https://res.cloudinary.com/kinside/image/upload/v1627996200/app_assets/kinside_app/illustration-block1.png',
  boyGirl: 'https://res.cloudinary.com/kinside/image/upload/v1627996200/app_assets/illustration-boygirl.png',
  boyGirl2: 'https://res.cloudinary.com/kinside/image/upload/v1627996200/app_assets/illustration-boygirl.png',
  warning: 'https://res.cloudinary.com/kinside/image/upload/v1692796791/app_assets/warn.png',
  chalkboard: 'https://res.cloudinary.com/kinside/image/upload/v1627995899/app_assets/kinside_app/illustration-chalkboard.png',
  child1: 'https://res.cloudinary.com/kinside/image/upload/v1648078117/app_assets/illustration-child1.png',
  child2: 'https://res.cloudinary.com/kinside/image/upload/v1648078117/app_assets/illustration-child2.png',
  employee: 'https://res.cloudinary.com/kinside/image/upload/v1627995900/app_assets/illustration-employee.png',
  girl: 'https://res.cloudinary.com/kinside/image/upload/v1685556838/app_assets/illustration-girl.svg',
  mailbox: 'https://res.cloudinary.com/kinside/image/upload/v1685556838/app_assets/illustration-mailbox.svg',
  map: 'https://res.cloudinary.com/kinside/image/upload/v1627995900/app_assets/kinside_app/illustration-map.png',
  momDaughter: 'https://res.cloudinary.com/kinside/image/upload/v1627995900/app_assets/illustration-mom-daughter.png',
  momDaughter2: 'https://res.cloudinary.com/kinside/image/upload/v1627995900/app_assets/illustration-mom-daughter2.png',
  moon: 'https://res.cloudinary.com/kinside/image/upload/v1627995899/app_assets/illustration-moon.png',
  plants: 'https://res.cloudinary.com/kinside/image/upload/v1627995900/app_assets/illustration-plants.png',
  search: 'https://res.cloudinary.com/kinside/image/upload/v1648078117/app_assets/illustration-search.png',
  sun: 'https://res.cloudinary.com/kinside/image/upload/v1627995900/app_assets/illustration-sun.png',
  toys: 'https://res.cloudinary.com/kinside/image/upload/v1627995900/app_assets/illustration-toys3.png',
  trees: 'https://res.cloudinary.com/kinside/image/upload/v1627995900/app_assets/kinside_app/illustration-trees.png',
  block4: 'https://res.cloudinary.com/kinside/image/upload/v1652319230/app_assets/static_pages/employers/illustration-block-4.png',
  block5: 'https://res.cloudinary.com/kinside/image/upload/v1700258898/app_assets/static_pages/employers/illustration-block5.png',
  tada: 'https://res.cloudinary.com/kinside/image/upload/v1700602178/app_assets/illustration-tada.svg',
};

const facilityPlaceholder = [
  'https://res.cloudinary.com/kinside/image/upload/v1627997595/app_assets/kinside_app/facility-placeholders/facility-placeholder-19_2x.jpg',
  'https://res.cloudinary.com/kinside/image/upload/v1627997598/app_assets/kinside_app/facility-placeholders/facility-placeholder-18_2x.jpg',
  'https://res.cloudinary.com/kinside/image/upload/v1627997594/app_assets/kinside_app/facility-placeholders/facility-placeholder-15_2x.jpg',
  'https://res.cloudinary.com/kinside/image/upload/v1627997597/app_assets/kinside_app/facility-placeholders/facility-placeholder-14_2x.jpg',
  'https://res.cloudinary.com/kinside/image/upload/v1627997593/app_assets/kinside_app/facility-placeholders/facility-placeholder-13_2x.jpg',
  'https://res.cloudinary.com/kinside/image/upload/v1627997596/app_assets/kinside_app/facility-placeholders/facility-placeholder-12_2x.jpg',
  'https://res.cloudinary.com/kinside/image/upload/v1627997593/app_assets/kinside_app/facility-placeholders/facility-placeholder-11_2x.jpg',
  'https://res.cloudinary.com/kinside/image/upload/v1627997597/app_assets/kinside_app/facility-placeholders/facility-placeholder-9_2x.jpg',
  'https://res.cloudinary.com/kinside/image/upload/v1627997597/app_assets/kinside_app/facility-placeholders/facility-placeholder-7_2x.jpg',
  'https://res.cloudinary.com/kinside/image/upload/v1627997595/app_assets/kinside_app/facility-placeholders/facility-placeholder-6_2x.jpg',
  'https://res.cloudinary.com/kinside/image/upload/v1627997597/app_assets/kinside_app/facility-placeholders/facility-placeholder-5_2x.jpg',
  'https://res.cloudinary.com/kinside/image/upload/v1627997594/app_assets/kinside_app/facility-placeholders/facility-placeholder-4_2x.jpg',
  'https://res.cloudinary.com/kinside/image/upload/v1627997595/app_assets/kinside_app/facility-placeholders/facility-placeholder-3_2x.jpg',
  'https://res.cloudinary.com/kinside/image/upload/v1627997593/app_assets/kinside_app/facility-placeholders/facility-placeholder-2_2x.jpg',
  'https://res.cloudinary.com/kinside/image/upload/v1627997596/app_assets/kinside_app/facility-placeholders/facility-placeholder-1_2x.jpg',
  'https://res.cloudinary.com/kinside/image/upload/v1627997593/app_assets/kinside_app/facility-placeholders/facility-placeholder-0_2x.jpg',
];

const marker = {
  location: 'https://res.cloudinary.com/kinside/image/upload/v1627997506/app_assets/kinside_app/Marker/Location.svg',
  specific: {
    match: {
      hovered: 'https://res.cloudinary.com/kinside/image/upload/v1648844852/app_assets/kinside_app/Marker/Match/Hovered.svg',
      viewed: 'https://res.cloudinary.com/kinside/image/upload/v1648844852/app_assets/kinside_app/Marker/Match/Viewed.svg',
      default: 'https://res.cloudinary.com/kinside/image/upload/v1648844852/app_assets/kinside_app/Marker/Match/Default.svg',
    },
    saved: {
      hovered: 'https://res.cloudinary.com/kinside/image/upload/v1628152698/app_assets/kinside_app/Marker/Saved/Hovered.svg',
      viewed: 'https://res.cloudinary.com/kinside/image/upload/v1628152697/app_assets/kinside_app/Marker/Saved/Viewed.svg',
      default: 'https://res.cloudinary.com/kinside/image/upload/v1628152696/app_assets/kinside_app/Marker/Saved/Default.svg',
    },
    disabled: {
      hovered: 'https://res.cloudinary.com/kinside/image/upload/v1628152685/app_assets/kinside_app/Marker/Disabled/Hovered.svg',
      viewed: 'https://res.cloudinary.com/kinside/image/upload/v1628152685/app_assets/kinside_app/Marker/Disabled/Viewed.svg',
      default: 'https://res.cloudinary.com/kinside/image/upload/v1628152685/app_assets/kinside_app/Marker/Disabled/Default.svg',
    },
    favorited: {
      default: 'https://res.cloudinary.com/kinside/image/upload/v1628152685/app_assets/kinside_app/Marker/Favorited/Default.svg',
      viewed: 'https://res.cloudinary.com/kinside/image/upload/v1628152685/app_assets/kinside_app/Marker/Favorited/Viewed.svg',
      hovered: 'https://res.cloudinary.com/kinside/image/upload/v1628152685/app_assets/kinside_app/Marker/Favorited/Hovered.svg',
    },
    ignored: {
      viewed: 'https://res.cloudinary.com/kinside/image/upload/v1628152688/app_assets/kinside_app/Marker/Ignored/Viewed.svg',
      default: 'https://res.cloudinary.com/kinside/image/upload/v1628152688/app_assets/kinside_app/Marker/Ignored/Default.svg',
      hovered: 'https://res.cloudinary.com/kinside/image/upload/v1628152688/app_assets/kinside_app/Marker/Ignored/Hovered.svg',
    },
    nonPartner: {
      default: 'https://res.cloudinary.com/kinside/image/upload/v1655511461/app_assets/kinside_app/Marker/Non-partner/Default.svg',
      viewed: 'https://res.cloudinary.com/kinside/image/upload/v1655511461/app_assets/kinside_app/Marker/Non-partner/Viewed.svg',
      hovered: 'https://res.cloudinary.com/kinside/image/upload/v1655511461/app_assets/kinside_app/Marker/Non-partner/Hovered.svg',
    },
    offer: {
      default: 'https://res.cloudinary.com/kinside/image/upload/v1628152691/app_assets/kinside_app/Marker/Offer/Default.svg',
      viewed: 'https://res.cloudinary.com/kinside/image/upload/v1628152692/app_assets/kinside_app/Marker/Offer/Viewed.svg',
      hovered: 'https://res.cloudinary.com/kinside/image/upload/v1628152693/app_assets/kinside_app/Marker/Offer/Hovered.svg',
    },
    partner: {
      default: 'https://res.cloudinary.com/kinside/image/upload/v1628152694/app_assets/kinside_app/Marker/Partner/Default.svg',
      viewed: 'https://res.cloudinary.com/kinside/image/upload/v1628152694/app_assets/kinside_app/Marker/Partner/Viewed.svg',
      hovered: 'https://res.cloudinary.com/kinside/image/upload/v1628152694/app_assets/kinside_app/Marker/Partner/Hovered.svg',
    },
    payingPartner: {
      hovered: 'https://res.cloudinary.com/kinside/image/upload/v1628152696/app_assets/kinside_app/Marker/Paying%20Partner/Hovered.svg',
      viewed: 'https://res.cloudinary.com/kinside/image/upload/v1628152696/app_assets/kinside_app/Marker/Paying%20Partner/Viewed.svg',
      default: 'https://res.cloudinary.com/kinside/image/upload/v1628152696/app_assets/kinside_app/Marker/Paying%20Partner/Default.svg',
    },
  },
  zipBased: {
    nonPartner: {
      viewed: 'https://res.cloudinary.com/kinside/image/upload/v1628152690/app_assets/kinside_app/Marker/Non-partner%20%28Zip%20Only%29/Viewed.svg',
      default: 'https://res.cloudinary.com/kinside/image/upload/v1628152690/app_assets/kinside_app/Marker/Non-partner%20%28Zip%20Only%29/Default.svg',
      hovered: 'https://res.cloudinary.com/kinside/image/upload/v1628152690/app_assets/kinside_app/Marker/Non-partner%20%28Zip%20Only%29/Hovered.svg',
    },
    partner: {
      hovered: 'https://res.cloudinary.com/kinside/image/upload/v1628152696/app_assets/kinside_app/Marker/Partner%20%28Zip%20Only%29/Hovered.svg',
      viewed: 'https://res.cloudinary.com/kinside/image/upload/v1628152694/app_assets/kinside_app/Marker/Partner%20%28Zip%20Only%29/Viewed.svg',
      default: 'https://res.cloudinary.com/kinside/image/upload/v1628152694/app_assets/kinside_app/Marker/Partner%20%28Zip%20Only%29/Default.svg',
    },
  },

};

const marketingMaterials = {
  generalOnePager: 'https://res.cloudinary.com/kinside/image/upload/v1643059444/app_assets/marketing_materials/kinside-general-one-pager.png',
  parentalOnePager: 'https://res.cloudinary.com/kinside/image/upload/v1643059444/app_assets/marketing_materials/kinside-parental-leave-one-pager.png',
  presentation: 'https://res.cloudinary.com/kinside/image/upload/v1643059444/app_assets/marketing_materials/kinside-presentation-slides.png',
  childCareProfile: 'https://res.cloudinary.com/kinside/image/upload/v1643073544/app_assets/marketing_materials/child-care-provider.png',
  familySearch: 'https://res.cloudinary.com/kinside/image/upload/v1643073473/app_assets/marketing_materials/family-search.png',
  documentParents: 'https://res.cloudinary.com/kinside/image/upload/v1643225190/app_assets/marketing_materials/documents/Kinside_-_For_Parents.pdf',
  documentIntranet: 'https://res.cloudinary.com/kinside/image/upload/v1643225191/app_assets/marketing_materials/documents/Kinside_-_Intranet_Overview.pdf',
  documentParental: 'https://res.cloudinary.com/kinside/image/upload/v1643225190/app_assets/marketing_materials/documents/Kinside_-_Parental_Leave.pdf',
};

export {
  misc,
  illustrations,
  facilityPlaceholder,
  marker,
  kinsideLogo,
  legupByKinsideLogo,
  marketingMaterials,
};
