const accountGuard = (store) => async (to, from, next) => {
  await store.dispatch('isInitialized', undefined, { root: true });
  // eslint-disable-next-line no-underscore-dangle
  const { $userAccess } = store._vm;
  const canEnterConfirmAccount = $userAccess({ can: 'enterConfirmAccount' });
  const canEnterOnboarding = $userAccess({ can: 'enterOnboarding' });
  const canEnterPaymentOnboarding = $userAccess({ can: 'enterPaymentOnboarding' });
  const canEnterApp = $userAccess({ can: 'enterApp' });
  const canEnterPassword = $userAccess({ can: 'enterPassword' });

  if (to.name === 'settings') {
    next();
  } else if (canEnterPassword && to.name !== 'set-password') {
    next({
      name: 'set-password',
      query: to.query,
    });
  } else if (canEnterPaymentOnboarding && to.name !== 'payment-onboarding') {
    next({
      name: 'payment-onboarding',
      query: to.query,
    });
    // This takes the user to the confirm screen before onboarding
  } else if (!canEnterOnboarding && canEnterConfirmAccount && to.name !== 'confirm-account') {
    next({
      name: 'confirm-account',
      query: to.query,
    });
  } else if (!canEnterPaymentOnboarding && canEnterOnboarding && to.name !== 'onboarding') {
    next({
      name: 'onboarding',
      query: to.query,
    });
  // This takes users to the confirm screen again if they sign up without a valid domain
  } else if (!canEnterApp && !canEnterOnboarding && !canEnterPaymentOnboarding
    && !canEnterPassword && to.name !== 'confirm-account') {
    next({
      name: 'confirm-account',
      query: to.query,
    });
  } else {
    next();
  }
};

async function setupGuards(store, router) {
  await store.dispatch('isInitialized', undefined, { root: true });
  // eslint-disable-next-line no-underscore-dangle
  const { $userAccess } = store._vm;
  const canEnterOnboarding = $userAccess({ can: 'enterOnboarding' });
  const canEnterPaymentOnboarding = $userAccess({ can: 'enterPaymentOnboarding' });
  const canEnterApp = $userAccess({ can: 'enterApp' });
  const canEnterPassword = $userAccess({ can: 'enterPassword' });
  const canParentEnterPaymentOnboarding = $userAccess({ can: 'parentEnterPaymentOnboarding' });
  const guard = accountGuard(store);

  if (canEnterPaymentOnboarding || canEnterOnboarding || !canEnterApp || canEnterPassword) {
    router.beforeEach(guard);
    if (canEnterPassword && router.currentRoute.name !== 'set-password') {
      router.replace({ name: 'set-password' });
    } else if (canEnterPaymentOnboarding && router.currentRoute.name !== 'payment-onboarding') {
      router.replace({ name: 'payment-onboarding' });
    } else if (canEnterOnboarding && router.currentRoute.name !== 'onboarding') {
      router.replace({ name: 'onboarding' });
    } else if (!canEnterApp && !canEnterOnboarding && router.currentRoute.name !== 'confirm-account') {
      router.replace({ name: 'confirm-account' });
    }
  } else if (canParentEnterPaymentOnboarding && router.currentRoute.name !== 'accept-autopay') {
    router.beforeEach(guard);
    const acceptAutoPayLink = store.state.user.current.acceptAutopayLink;
    router.replace({ path: acceptAutoPayLink });
  }

  router.afterEach((to) => {
    store.dispatch('track/pageLoad', to.path);
  });
}

const confirmAccountGuard = (store) => async (to, from, next) => {
  await store.dispatch('isInitialized', undefined, { root: true });
  const { current } = store.state.user;
  const hasConfirmedAccount = current.isParent
    ? !!current.confirmedAt && !current.notVerified
    : !!current.confirmedAt;
  if (hasConfirmedAccount) {
    next('/');
  } else {
    next();
  }
};

const impersonatedGuard = (store) => async (to, from, next) => {
  await store.dispatch('isInitialized', undefined, { root: true });
  const { isImpersonated } = store.state.user.current;
  if (isImpersonated) {
    next();
  } else {
    next('/');
  }
};

const onboardingGuard = (store) => async (to, from, next) => {
  await store.dispatch('isInitialized', undefined, { root: true });
  const hasOnboarded = store.state.user.current.onboardingCompleted
    || store.state.user.current.paymentOnboardingCompleted;
  if (hasOnboarded) {
    next('/');
  } else {
    next();
  }
};

const paymentOnboardingGuard = (store) => async (to, from, next) => {
  await store.dispatch('isInitialized', undefined, { root: true });
  const { paymentOnboardingStarted, paymentOnboardingCompleted } = store.state.user.current;
  if (paymentOnboardingStarted && !paymentOnboardingCompleted) {
    next();
  } else {
    next('/');
  }
};

const enterPasswordGuard = (store) => async (to, from, next) => {
  await store.dispatch('isInitialized', undefined, { root: true });
  const { passwordSet } = store.state.user.current;
  if (!passwordSet) {
    next();
  } else {
    next('/');
  }
};

const beforeProviderEditingGuard = (store) => async (to, from, next) => {
  await store.dispatch('isInitialized', undefined, { root: true });
  const facilityId = to.query?.id;
  if (Number(facilityId)) {
    // set facility id on store
    await store.commit('setFacilityId', Number(facilityId));
    // fetch facility
    await store.dispatch('facilities/fetchFacilitiesToStore', {
      ids: [facilityId],
    });
    next();
  } else {
    // if there is no facility id, fetch all facilities for provider
    // and reload current request path with first facility id

    // fetch all provider facilities
    await store.dispatch('facilities/fetchFacilitiesForProvider', {
      page: 1,
    });
    const { facilities } = store.state.facilities.facilitiesResultsForProvider;
    next(`${to.path}?id=${facilities[0].id}`);
  }
};

const beforeHomeGuard = (store) => async (to, from, next) => {
  await store.dispatch('isInitialized', undefined, { root: true });

  // fetch all provider facilities
  await store.dispatch('facilities/fetchFacilitiesForProvider', {
    page: 1,
  });

  const userOnboardingNumLocations = store.state.user?.current?.numberOfLocations;

  const { facilities } = store.state.facilities.facilitiesResultsForProvider;
  // if there is more than 1 facility created or the number of locations
  // noted in onboarding is more than 1, goto /home
  // otherwise goto the dashboard screen for the first/only facility
  if (userOnboardingNumLocations > 1 || facilities?.length > 1) {
    next();
  } else {
    next(`/dashboard?id=${facilities[0].id}`);
  }
};

export {
  setupGuards,
  confirmAccountGuard,
  onboardingGuard,
  paymentOnboardingGuard,
  enterPasswordGuard,
  impersonatedGuard,
  beforeProviderEditingGuard,
  beforeHomeGuard,
};
