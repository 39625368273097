<template>
  <nav class="app-navigation" v-if="shouldShowNavigation()">
    <div
      class="app-navigation__left-side"
      :class="{'app-navigation__left-side--centered': isPreviewMode}"
    >
      <div class="app-navigation__mobile-btn" v-show="!shouldShowDashboardNav()">
        <slot name="mobile-nav-btn">
        </slot>
      </div>
      <a href="/" class="app-navigation__logo" :style="logoStyle"></a>
    </div>
    <section class="app-navigation__center" v-show="shouldShowDashboardNav() && !isPreviewMode">
      <a :href="urlWithId('/dashboard')" :class="btnClasses('/dashboard')">
        <span class="fal fa-tachometer"/>
        Dashboard
      </a>
      <a :href="urlWithId('/profile')" :class="btnClasses('/profile')">
        <span class="fal fa-stars"/>
        Your Profile
      </a>
      <a :href="urlWithId('/openings')" :class="btnClasses('/openings')">
        <span class="fal fa-chair"/>
        Open spots
      </a>
      <a :href="urlWithId('/payments')" :class="btnClasses('/payments')">
        <span class="fal fa-usd-circle"/>
        Enrollments
      </a>
      <a :href="urlWithId('/business')" :class="btnClasses('/business')">
        <span class="fal fa-cog"/>
        Settings
      </a>
    </section>
    <section class="app-navigation__right-side" v-if="!isPreviewMode">
      <span class="app-navigation__nav-item">
        <slot name="drop-down">
        </slot>
      </span>
    </section>
  </nav>
</template>

<script>
import authMixin from '@profile/mixins/auth';
import { kinsideLogo } from '@images';
import careProviderUtils from '../../common/mixins/care-provider-utils';

export default {
  name: 'CareProviderNavigation',
  mixins: [
    careProviderUtils,
    authMixin,
  ],
  props: {
    avatar: {
      type: String,
      default: '',
    },
    userProvider: {
      type: Boolean,
      required: true,
    },
    legupUser: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    logoStyle() {
      return { 'background-image': `url(${kinsideLogo.light})` };
    },
  },
  methods: {
    btnClasses(pathname) {
      const pathnameMap = {
        '/dashboard': ['/dashboard'],
        '/profile': ['/profile'],
        '/openings': ['/openings', '/tours', '/programs'],
        '/payments': ['/payments', '/families'],
        '/business': ['/business', '/business/edit', '/fees/edit'],
      };
      if (pathnameMap[pathname]?.includes(window.location.pathname)) {
        return 'navigation-btn navigation-btn__active';
      }
      return 'navigation-btn';
    },
    shouldShowDashboardNav() {
      return this.userProvider && !this.$store.state.user.current.providerOnboardedToLegup
        && (window.location.pathname === '/settings'
        || (this.$route.path !== '/' && this.$route.path !== '/home'));
    },
    shouldShowNavigation() {
      return this.userProvider && !this.legupUser;
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-btn {
  display: inline-block;
  padding: 16px;
  color: var(--viridian-0);
}

.navigation-btn__active {
  color: var(--gold-15);
  background-color: var(--viridian-70);
}

.app-navigation {
  position: sticky;
  top: 0;
  z-index: var(--z-navigation);
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  height: var(--nav-height);
  padding: var(--grid-unit) calc(var(--grid-unit) * 2);
  background: var(--viridian-50);

  &__mobile-btn {
    flex-grow: 0;
    flex-shrink: 1;
    margin-right: 15px;
    color: var(--viridian-5);

    @media screen and (min-width: 992px) {
      display: none;
    }
  }

  &__logo {
    display: block;
    width: 93.8px;
    height: 20px;
    margin: 0;
    background-repeat: no-repeat;
    background-size: 93.8px 20px;

    @media screen and (min-width: 640px) {
      width: 117.4px;
      height: 25px;
      background-size: 117.4px 25px;
    }
  }

  &__logo-container {
    width: 100%;
    text-align: center;

    .app-navigation__logo {
      display: inline-block;
    }
  }

  &__left-side,
  &__right-side {
    display: flex;
    align-items: center;
  }

  &__left-side--centered {
    margin-left: auto;
    margin-right: auto;
  }

  &__nav-item {
    padding-right: 15px;
    font-size: 16px;
    color: var(--viridian-50);

    &:last-child {
      padding-right: 0;
    }

    &--selected {
      font-weight: 600;
    }
  }

  &__notifications-link {
    display: block;
    padding: 10px;
    text-align: center;
  }
}

.app-navigation__center {
  @media screen and (max-width: 992px) {
    display: none;
  }
}

</style>
